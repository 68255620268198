/*!
 * Start Bootstrap - Simple Sidebar HTML Template (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

/* Toggle Styles */

#wrapper {
    padding-left: 0;
    transition: all 0.5s ease;
    width: 100%;
    height: 100%;
    position: relative;

    &.toggled {
        padding-left: $sidebar-width;
    }
}

#topbar-wrapper {
    z-index: 1001;
    position: fixed;
    display: block;
    top: 0;
    left: 0px;
    padding-left: $base-padding;
    height: $topbar-height;
    line-height: $topbar-height;
    width: 100vw;
    background-color: $base-background;

    > * {
        pointer-events: auto;
    }

    .topbar-brand {
        font-size: 18px;
        font-style: italic;

        a {
            color: #000;

            &:hover {
                background: none;
            }
        }
    }

    button {
        display: block;
        position: absolute;
        right: $base-padding;
        top: 11px;
        padding: 5px 10px;
        font-size: 18px;
        background-color: darken($base-background, 5%);

        &:hover {
            background-color: darken($base-background, 10%);
        }
    }

}

#sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: $sidebar-width;
    width: 0;
    height: 100%;
    margin-left: -$sidebar-width;
    overflow-y: auto;
    background: $base-background;
    transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
    width: $sidebar-width;
}

#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: $topbar-height $base-padding-sm $base-padding-sm $base-padding-sm;

    .content {
       overflow-x: scroll;
    }
}

#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -($sidebar-width - $base-padding);
}

/* Sidebar Styles */

.sidebar-nav {
    position: absolute;
    top: 0;
    width: $sidebar-width;
    padding: 0;
    padding-top: $topbar-height;
    list-style: none;
    font-family: sans-serif;

    li {
        text-indent: $base-padding;
        line-height: 20px;

        &.active {
            font-style: italic;
        }

        a {
            display: block;
            text-decoration: none;
            color: #000;

            &:hover {
                text-decoration: none;
                // background: rgba(0,0,0,0.025);
            }

            &:active,
            &:focus {
                text-decoration: none;
            }
        }
    }

    > .divider {
        font-weight: bold;
        margin-top: 1em;

        &:first-of-type {
            margin-top: 0px;
        }
    }

}


// Large screens
@media(min-width: $base-screen-sm) {
    #wrapper {
        padding-left: $sidebar-width;

        &.toggled {
            padding-left: 0;
        }
    }

    #sidebar-wrapper {
        width: $sidebar-width;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 0;
    }

    #page-content-wrapper {
        padding: $base-padding;
        padding-left: 0px;
        position: relative;


        .content {
          overflow-x: inherit;
          margin-top: 43px;
        }
    }

    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
    }

    #topbar-wrapper {
        button {
            display: none;
        }
    }
}
