a,
a:hover,
a:active,
a:focus {
    text-decoration: none;
    color: inherit;
}

.copyright {
    color: gray;
    width: 200px;
}
