body, html {
    width: 100%;
    height: 100%;
    background-color: $base-background;
}

#page-content-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 98vh;

    .content {
        white-space: normal;
        flex-grow: 1;
    }

    .footer {
        margin-top: 1.75em;
    }
}



.page-info {
    display: block;
    padding-bottom: $base-padding;
    width: 100%;
    max-width: $base-art-maxThumbWidth;

    p {
        font-size: $base-fontSize;
        max-width: $base-art-maxThumbWidth;
    }

    h1 {
        font-size: $base-fontSize-header;
    }

    h1:first-child {
      margin-top: 0;
    }
}
