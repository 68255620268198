
.art-horizontal {
    display: block;
    white-space: nowrap;

    .art-thumb {
        display: inline-block;
        padding-right: $art-margin;

        &:last-child {
          padding-right: 0;
        }
    }
}
.art-vertical {
    display: block;

    .art-thumb {
        display: block;
        padding-bottom: $art-margin;
    }


}

.art-thumb {
  img {
    max-height: 85vh;
  }
}

.art-cover {
  max-height: 85vh;
}


.art-cover-container {
    position: relative;
}

.art-bio-container {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 1200px;
}