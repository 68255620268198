

$base-background: #FFFFFF;
$base-fontSize: 12px;
$base-fontSize-header: 16px;

$base-screen-sm: 1024px;
$base-padding: 20px;
$base-padding-sm: 15px;

$base-art-maxThumbWidth: 700px;
$art-margin: 20vh;

$topbar-height: 60px;

$sidebar-width: 220px;


@import '_vender/startbootstrap-simple-sidebar';
@import '_partials/layout';
@import '_partials/text';
@import '_partials/art';
